@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo+2&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anta&display=swap);
body {
  background-color: azure;
  margin: 0%;
}

.tere{
  margin-top: 300px;
}
.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  height: 500px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

a {
  text-decoration: none;
  color: white;
} 
.header {
  z-index: 1000;
  position: fixed;
  display: flex;
  top: 0;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(45deg, black, darkblue, black);
  min-width: 100%;
  height: 120px;
  color: white;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.5);
}
.header h1 {
  text-align: center;
  font-size: 40px;
  margin: 15px;
  font-family: "Roboto", sans-serif;
}

.header img{
  width: 250px;
}
.nav {
  display: flex;
  width: 300px;
  flex-direction: row;
  justify-content: space-evenly;
  list-style: none;
}
.navButton {
  display: inherit;
  background-color: transparent;
  border: none;
  color: white;
  font-family: "Anta", sans-serif;
  font-size: 20px;
}
.header button:hover,
button:active {
  color: gray;
  border-bottom: 1px solid gray;
}
.main-content {
  margin-top: 100px;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 40px;
  background: linear-gradient(45deg, black, darkblue, black);
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  box-shadow: 20px 0px 20px rgba(0, 0, 0, 0.5);
}

.services {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  margin: 0px auto 100px auto;
  width: 100%;
  font-family: "Josefin Sans", sans-serif;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.package {
  background: linear-gradient(45deg, black, rgb(10, 10, 186));
  color: white;
  padding: 10px;
  width: 360px;
  height: 475px;
  margin: 15px;
  box-shadow: 0px 0px 10px gray;
  font-family: "Josefin Sans", sans-serif;
  /* font-family: "Anta", sans-serif; */

  text-align: center;
  border-radius: 10px;
}
.package li {
  text-align: left;
  line-height: 1.6;
  font-size: 20px;
}

.class-one {
  text-align: center;
    font-family: "Anta", sans-serif;
  margin-top: 150px;
}

.booking {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 100px auto;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
  width: 50%;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  height: 70px;
  width: 320px;
  background: linear-gradient(45deg, red, blue);
  color: white;
  font-size: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px black;
}
.nav-slide {
  display: none;
}
.icons {
  height: 40px;
  width: 40px;
  margin: auto 10px;
}
.booking-title {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  width: 100%;
  background: linear-gradient(white, rgb(10, 10, 186), rgb(10, 10, 186), white);
  color: white;
}
.mobile-nav {
  display: none;
}

.welcome-message{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 100%;
  background: linear-gradient(0deg, rgba(34,193,195,0.5) 0%, rgba(22,41,227,1) 100%);
  font-family: "Anta", sans-serif;
  font-size: 20px;
  color: azure;
  text-align: center;
  margin-bottom: 20%;
  padding-top: 50px;
}
/* .welcome-message img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */



.veh{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.schedule{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background: linear-gradient(45deg, darkblue, darkorchid);
  width: 400px;
  color: white;
  /* font-family: "Josefin Sans", sans-serif; */
  font-family: "Anta", sans-serif;

  border-radius: 15px;
  box-shadow: 0px 0px 10px gray;
}
.schedule li{
  list-style: none;
  line-height: 1.5;
}

.text-end{
  text-align: end;
}

.text-start{
  text-align: left;
}

@media only screen and (max-width: 600px) {
  body {
    display: block;
  }
  .header {
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.5);
  }
  .header ul button {
    margin-left: -50px;
    color: white;
    font-size: 25px;
    line-height: 3;
  }
  .main-content {
    min-width: 100%;
  }
  .welcome-message img {
    width: 100%;
    height: 400px;
  }
  .services {
    flex-direction: column;
    margin: none;

  }
  .package {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
 
  .booking {
    min-width: 100%;
    font-size: 12px;
    margin: 0px;
  }
  .contact {
    min-width: 95%;
    height: 200px;
    font-size: 30px;
    flex-direction: column;
  }
  .icons {
    height: 75px;
    width: 60px;
  }
  .footer {
    height: 40px;
    font-size: 12px;
  }
  .nav-slide {
    display: flex;
    position: fixed;
    right: 15px;
    height: 40px;
    width: 40px;
    padding: 5px;
    border: 1px solid gray;
    border-radius: 5px;
  }
  .nav {
    display: none;
  }
  .mobile-nav {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100px;
    height: 300px;
    margin: 0px;
    width: 100%;
    left: -15px;
    background-color: black;
    list-style: none;
    background: linear-gradient(45deg, black, darkblue, black);
  }
  .mobile-nav-button {
    display: inherit;
    background-color: transparent;
    border: none;
    color: white;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
  }

  .booking-title {
    height: 200px;
  }
  .veh{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .schedule{
    width: 80%;
    margin-bottom: 100px;
  }
}


